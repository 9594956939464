import React from "react";
import { Location } from "./Location";

interface LocationsProps {}

export const Locations: React.FC<LocationsProps> = () => {
  return (
    <div className="locations-container">
      <Location
        region="Southern California"
        state="CA"
        street1="1455 Columbia Ave"
        city="Riverside"
        zipCode="92507"
        telephone="(909) 946-3697"
        fax="(909) 608-1860"
        mapSource="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3307.615697713797!2d-117.34331998478629!3d34.00240358061959!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dcade1b4cf4355%3A0x77fc20b47e563b7f!2sATI+Windows!5e0!3m2!1sen!2sus!4v1458114257426"
      />
      <Location
        region="Phoenix"
        state="AZ"
        street1="2417 S 50th Ave"
        city="Phoenix"
        zipCode="85043"
        telephone="(909) 946-3697"
        fax="(909) 608-1860"
        mapSource="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13319.659745047842!2d-112.1659355!3d33.425462!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa6687ea90fa4d48b!2sATI%20Windows!5e0!3m2!1sen!2sus!4v1586418636985!5m2!1sen!2sus"
      />
      <Location
        region="Las Vegas"
        state="NV"
        street1="1950 East Maule"
        city="Las Vegas"
        zipCode="89119"
        telephone="(702) 946-3697"
        fax="(702) 253-9914"
        mapSource="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12900.658043032125!2d-115.1253629!3d36.0650913!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x814ea327f815b975!2sATI%20Windows!5e0!3m2!1sen!2sus!4v1574575903579!5m2!1sen!2sus"
      />
    </div>
  );
};
