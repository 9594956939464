import React from "react";

interface DoorsProps {}

export const Doors: React.FC<DoorsProps> = () => {
  return (
    <div className="home-doors-container" id="doors">
      <div className="home-doors-content">
        <div className="home-doors-left">
          <div className="home-container-title">Doors</div>
          <div className="home-doors-info">
            Different from your old door. Energy efficient, quiet and safe. No
            size is too big or too small. We manufacture patio doors as small as
            five feet and as large as sixteen feet — perfect for any size you
            need.
          </div>
        </div>
        <div className="home-doors-images">
          <div className="home-doors-image-1">
            <img src="http://vinylwindows.co/door.png" alt="doors-1" />
          </div>
        </div>{" "}
      </div>
    </div>
  );
};
