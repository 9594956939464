import React from "react";

interface HeroVideoProps {}

export const HeroVideo: React.FC<HeroVideoProps> = () => {
  return (
    <div className="home-hero-video-container">
      <video
        className="home-hero-video"
        preload="auto"
        autoPlay={true}
        loop={true}
        muted={true}
      >
        <source src="https://cldup.com/6wURyVYj1p.mp4" type="video/mp4" />
      </video>
      <div className="home-hero-overlay">
        <div className="home-hero-overlay-line-1">We are</div>
        <div className="home-hero-overlay-line-2">Your window manufacturer</div>
        <div className="home-hero-overlay-line-3">
          Our products deliver the best quality for the right price.
        </div>
      </div>
    </div>
  );
};
