import React from "react";
import { addBusinessDays, format } from "date-fns";

interface CTAProps {}

export const CTA: React.FC<CTAProps> = () => {
  const onClick = () => {
    window.open("tel:+19099463697");
  };

  return (
    <div className="cta-popup" onClick={onClick}>
      Call now and get your windows by{" "}
      {format(addBusinessDays(new Date(), 5), "M/dd")}
    </div>
  );
};
