import React from "react";

interface WindowsProps {}

export const Windows: React.FC<WindowsProps> = () => {
  return (
    <div className="home-windows-container" id="windows">
      <div className="home-windows-content">
        <div className="home-windows-left">
          <div className="home-container-title">Windows</div>
          <div className="home-windows-info">
            Whether your job is retrofit or new construction, our windows come
            in a variety of orientations and glass types. Contact us today for
            more information.
          </div>
        </div>
        <div className="home-windows-images">
          <div className="home-windows-image-1">
            <img src="http://vinylwindows.co/window.png" alt="windows-1" />
          </div>
          <div className="home-windows-image-2">
            <img src="http://vinylwindows.co/window3.png" alt="windows-2" />
          </div>
        </div>
      </div>
    </div>
  );
};
