import React from "react";

interface AboutUsProps {}

export const AboutUs: React.FC<AboutUsProps> = () => {
  return (
    <div className="about-us-container">
      <div className="home-container-title">About Us</div>
      <div className="about-us-text">
        The Right Window Company is a family owned and operated company that has
        served builders in California for over 40 years. No matter the size we
        treat every customer with the same level of care and respect. All of our
        products are energy star rated and every component is made in America.
        We are not just a store - we are experts with experience you can trust.
      </div>
    </div>
  );
};
