import React, { useState } from "react";

interface ContactUsProps {}

export const ContactUs: React.FC<ContactUsProps> = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [error, setError] = useState<string | null>(null);

  const onCallPhone = () => {
    window.open("tel:+19099463697");
  };

  const onClickEmail = (e: any) => {
    e.preventDefault();
    if (!name) {
      setError("Please enter your name");
    } else if (!phone) {
      setError("Please enter your phone number");
    }

    if (name && phone) {
      setError(null);
      window.open(
        `mailto:sales@atiwindows.com?subject=I need a quote&body=Name: ${name}%0D%0A
Phone Number: ${phone}%0D%0A
Enter any details if you wish (or just wait for us to call during business hours).`
      );
    }
  };

  const onSetName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.currentTarget.value);
  };

  const onSetPhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(e.currentTarget.value);
  };

  return (
    <div className="contact-us-container" id="contact">
      <div className="contact-us-byline">
        We are not just a store, we are your partners on your next project.
      </div>
      <div className="contact-us-call-us">Call us to get started:</div>

      <div className="contact-us-phone-number" onClick={onCallPhone}>
        1 (909) 946-3697
      </div>

      <div className="contact-us-hours">8am to 4:30pm PST Mon-Fri</div>

      <div className="contact-us-email">Don’t have time to talk?</div>
      <form className="contact-us-email-form">
        <div className="contact-us-email-form-container">
          <div className="contact-us-email-field">
            <input onChange={onSetName} value={name} placeholder="Name" />
          </div>
          <div className="contact-us-email-field">
            <input
              onChange={onSetPhone}
              value={phone}
              placeholder="Phone number"
            />
          </div>
          {error && <div className="contact-us-form-error">{error}</div>}
          <button
            className="contact-us-email-button"
            type="submit"
            onClick={onClickEmail}
          >
            Send us an email
          </button>
        </div>
      </form>
    </div>
  );
};
