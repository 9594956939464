import React from "react";

interface LocationProps {
  region: string;
  street1: string;
  city: string;
  state: string;
  zipCode: string;
  telephone: string;
  fax: string;
  mapSource: string;
}

export const Location: React.FC<LocationProps> = ({
  city,
  region,
  street1,
  state,
  zipCode,
  telephone,
  fax,
  mapSource,
}) => {
  return (
    <div className="location-container">
      <div className="location-info">
        <div className="location-region">{region}</div>
        <div className="location-address-1">{street1}</div>
        <div className="location-address-2">{`${city}, ${state} ${zipCode}`}</div>
        <div className="location-telephone">Tel: {telephone}</div>
        <div className="location-telephone">Fax: {fax}</div>
      </div>
      <iframe
        src={mapSource}
        width="100%"
        height={250}
        frameBorder={0}
        title={region}
      />
    </div>
  );
};
