import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsers,
  faLightbulbOn,
  faFlagUsa,
} from "@fortawesome/pro-duotone-svg-icons";

interface FamilyOwnedProps {}

export const FamilyOwned: React.FC<FamilyOwnedProps> = () => {
  const [hoveredState, setHoveredState] = useState("family");
  const lightbulbClassNames = `family-owned-icon ${
    hoveredState === "energy" && "lightbulb-icon"
  }`;
  const familyClassNames = `family-owned-icon ${
    hoveredState === "family" && "family-icon"
  }`;
  const flagClassNames = `family-owned-icon ${
    hoveredState === "flag" && "flag-icon"
  }`;

  return (
    <div className="family-owned-container">
      <div className="family-owned-icons">
        <div
          className={familyClassNames}
          onMouseOver={() => setHoveredState("family")}
        >
          <FontAwesomeIcon icon={faUsers} />
        </div>
        <div
          className={lightbulbClassNames}
          onMouseOver={() => setHoveredState("energy")}
        >
          <FontAwesomeIcon icon={faLightbulbOn} />
        </div>
        <div
          className={flagClassNames}
          onMouseOver={() => setHoveredState("flag")}
        >
          <FontAwesomeIcon icon={faFlagUsa} />
        </div>
      </div>
      <div className="family-owned-text">
        {sectionDescription[hoveredState]}
      </div>
    </div>
  );
};

const sectionDescription: { [index: string]: string } = {
  family:
    "The Right Window Company is a family owned company with over 40 years of experience working with tract builders and contractors.",
  energy: "All of our products are Energy Star rated and made to last.",
  flag: "Our windows and doors are made right here in America.",
};
