import React from "react";
import { Header } from "./modules/Layout/Header";
import { HomeContainer } from "./modules/Home/Container";
import { CTA } from "./modules/Layout/CTA";

function App() {
  return (
    <div className="App">
      <Header />
      <CTA />
      <HomeContainer />
    </div>
  );
}

export default App;
