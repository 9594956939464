import React from "react";

interface ProductProps {}

export const Product: React.FC<ProductProps> = () => {
  return (
    <div className="home-product-container">
      <div className="home-container-title">The product</div>
      <div className="home-product-info">
        One-third of a home's energy loss occurs through windows and doors.
        Right Window products use the latest production methods to ensure
        perfect craftsmanship and leading energy efficiency. Energy saved is
        money earned.
      </div>
    </div>
  );
};
