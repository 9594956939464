import React from "react";
import { HeroVideo } from "./components/HeroVideo";
import { FamilyOwned } from "./components/FamilyOwned";
import { Product } from "./components/Product";
import { Windows } from "./components/Windows";
import { Doors } from "./components/Doors";
import { AboutUs } from "./components/AboutUs";
import { ContactUs } from "./components/ContactUs";
import { Locations } from "./components/Locations";

interface HomeContainerProps {}

export const HomeContainer: React.FC<HomeContainerProps> = () => {
  return (
    <div className="home-container">
      <HeroVideo />
      <FamilyOwned />
      <Product />
      <Windows />
      <Doors />
      <ContactUs />
      <Locations />
      <AboutUs />
    </div>
  );
};
