import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

interface HeaderProps {}

export const Header: React.FC<HeaderProps> = () => {
  const onCallPhone = () => {
    window.open("tel:+19099463697");
  };

  return (
    <div className="header-container">
      <div className="header-left">
        <img src="http://vinylwindows.co/logo2.png" width={300} alt="logo" />
      </div>
      <div className="header-right">
        <div className="header-nav">
          <div className="header-nav-item">
            <AnchorLink href="#windows" offset="70">
              Windows
            </AnchorLink>
          </div>
          <div className="header-nav-item">
            <AnchorLink href="#doors" offset="70">
              Doors
            </AnchorLink>
          </div>
          <div className="header-nav-item header-contact">
            <AnchorLink href="#contact" offset="70">
              Contact
            </AnchorLink>
          </div>
          <div className="header-nav-item header-phone" onClick={onCallPhone}>
            1 (909) 946-3697
          </div>
        </div>
      </div>
    </div>
  );
};
